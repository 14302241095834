export const recordTypes = {
  STARTED: 'started',
  NOTSTARTED: 'not_started',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  FINISHED: 'finished',
};

export const visitTypes = {
  medicationManagement: "Medication Management",
  initialPsychiatricEvaluation: "Initial Evaluation",
  psychotherapyOnly: "Psychotherapy",
}

const templateUrl = 'https://pmhscribe.com/intg/coding/extensions/template-type';

export const defaultTemplateStatuses = [
  { display: 'Active', code: 'final' },
  { display: 'Inactive', code: 'preliminary' },
];

export const defaultTemplateTypes = [
  { display: 'Visit Type', code: 'visit-type', system: templateUrl, },
  { display: 'Additonal Note', code: 'additional-note', system: templateUrl, },
  { display: 'Custom Note', code: 'custom-note', system: templateUrl, }
]

export const defaultPractitionerRoles = [
  {
    system: 'https://pmhscribe.com/intg/coding/practitioner-roles',
    code: 'prescriber',
    display: 'Prescriber',
  },
  {
    system: 'https://pmhscribe.com/intg/coding/practitioner-roles',
    code: 'therapist',
    display: 'Therapist',
  },
  {
    system: 'https://pmhscribe.com/intg/coding/practitioner-roles',
    code: 'psychological-testing',
    display: 'Psychological Testing',
  },
]

export const olddefaultTemplateTypes = [
  {
    display: 'Initial Psychiatric Eval',
    code: "initial-psychiatric-eval",
    system: templateUrl,
  },
  {
    display: 'Medication Management',
    code: 'medication-management',
    system: templateUrl,
  },
  {
    display: 'Psychotherapy Add-On',
    code: 'psychotherapy-add-on',
    system: templateUrl,
  },
  {
    display: 'Psychotherapy',
    code: 'psychotherapy-only',
    system: templateUrl,
  },
  {
    display: 'Orders',
    code: 'orders',
    system: templateUrl,
  },
  {
    display: 'Referrals',
    code: 'referrals',
    system: templateUrl,
  },
  {
    display: 'Patient Instructions',
    code: 'patient-instructions',
    system: templateUrl,
  },
  {
    display: 'Statement of Service',
    code: 'statement-of-service',
    system: templateUrl,
  },
]

export interface ICDCodeProps {
  code: string;
  label: string;
  value?: string;
}

export const defaultRaceOptions = [
  { value: '2106-3', label: 'White' },
  { value: '2054-5', label: 'Black or African American' },
  { value: '2186-5', label: 'Hispanic or Latino' },
  { value: '2028-9', label: 'Asian' },
  { value: '1002-5', label: 'American Indian or Alaska Native' },
  // { value: '21112-8', label: 'Middle Eastern or North African' },
  { value: '2131-1', label: 'Other' },
  // { value: '2148-5', label: 'Prefer not to answer' },
]

export const roleInitialNoteMap: { [key: string]: string } = {
  'Prescriber': 'Medication Management',
  'Therapist': 'Psychotherapy',
  'Psychological Testing': 'Initial Evaluation'
};