import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Center, Pagination, Table, Text, Tooltip, createStyles } from '@mantine/core';
import axios from '../utils/axios';
import { useMedplum } from '@medplum/react';
import { useParams } from 'react-router-dom';
import { IconTrash } from '@tabler/icons-react';
import ConfirmBox from '../patients/ConfirmBox';
import { toast } from 'react-toastify';
import { deletePatientResponse } from '../utils/util';

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },
  tr: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  },
  th: {
    padding: '0 !important',
  },
  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

interface PatientResource {
  resource: {
    name?: { given?: string[] }[];
    birthDate?: string;
    sessionDescription?: string;
    meta?: { lastUpdated?: string };
  };
}

interface PatientTableProps {
  patientResponse: PatientResource[];
  classes: ReturnType<typeof useStyles>['classes'];
  handleDeleteIconClick: (resource: any) => void;
}

const parseDate = (dateString: string) => {
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? new Date(dateString.replace(/-/g, '/')) : date;
};

const formatLastUpdated = (dateString: string) => {
  if (!dateString) return '';

  const date = parseDate(dateString);

  const dateOptions: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const startTime = date.toLocaleTimeString('en-US', timeOptions);
  const endTime = new Date(date.getTime() + 60 * 1000).toLocaleTimeString('en-US', timeOptions);

  return (
    <>
      {formattedDate}
      <br />
      {`${startTime} - ${endTime}`}
    </>
  );
};

const PatientTable: React.FC<PatientTableProps> = ({ patientResponse, classes, handleDeleteIconClick }) => (
  <Table>
    <thead>
      <tr>
        <th className="table-heading">Name</th>
        <th className="table-heading">Date of Birth</th>
        <th className="table-heading">Session Description</th>
        <th className="table-heading">Last Session Date & Time</th>
        <th className="table-heading" style={{ textAlign: 'center' }}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {patientResponse.length > 0 ? (
        patientResponse.map((data: PatientResource, index: number) => (
          <tr key={index} className={classes.tr}>
            <td>{data?.resource?.name?.[0]?.given?.[0] || ''}</td>
            <td>{data?.resource?.birthDate || ''}</td>
            <td>{data?.resource?.sessionDescription || ''}</td>
            <td>{data?.resource?.meta?.lastUpdated ? formatLastUpdated(data.resource.meta.lastUpdated) : ''}</td>
            <td className="actions" style={{ textAlign: 'center' }}>
              <Tooltip label="Delete Session" withArrow position="top" sx={{ fontSize: '12px', marginTop: '1px' }}>
                <IconTrash
                  size={18}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteIconClick(data.resource);
                  }}
                />
              </Tooltip>
            </td>
          </tr>
        ))
      ) : (
        <tr key={0} className={classes.tr}>
          <td colSpan={5} style={{ textAlign: 'center' }}>
            <Text>No Data</Text>
          </td>
        </tr>
      )}
    </tbody>
  </Table>
);

export function PatientList(): JSX.Element | null {
  const medplum = useMedplum();
  const { classes } = useStyles();
  const resourceId = useParams().id;
  const [patientResponse, setPatientResponse] = useState<PatientResource[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;
  const resource = window.location.pathname.includes('Practitioner') ? 'Practitioner' : 'Patient';
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [isDeletingLoader, setIsDeletingLoader] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState<string>('');

  const getPatientsList = useCallback(async () => {
    const token = await medplum.getAccessToken();
    const offset = (currentPage - 1) * perPage;
    const resourceType = resource === 'Practitioner' ? `general-practitioner=Practitioner/${resourceId}` : `patient=${resourceId}`;

    try {
      const response = await axios.get(
        `intg/get-patient-list?${resourceType}&_offset=${offset}&_count=20&_sort=-_lastUpdated&_total=accurate&active=true`,
        {
          headers: {
            Accept: 'application/fhir+json',
            Authorization: `Bearer ${token}`,
            'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }
      );
      setPatientResponse(response.data.entry || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [currentPage, medplum, resourceId, resource]);

  useEffect(() => {
    getPatientsList();
  }, [getPatientsList]);

  const handleDeleteIconClick = useCallback((resource: any) => {
    setDeletingUserId(resource.id);
    setShowConfirmBox(true);
  }, []);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    return patientResponse.slice(startIndex, endIndex);
  }, [currentPage, patientResponse, perPage]);

  const handleConfirmDelete = async () => {
    setIsDeletingLoader(true);
    try {
      const response = await deletePatientResponse(medplum, deletingUserId);
      if (response) {
        toast.success("Patient deleted successfully!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setIsDeletingLoader(false);
        setShowConfirmBox(false);
        // Call getPatientsList to refresh the list
        getPatientsList();
      }
    } catch (error) {
      console.error('Error deleting patient:', error);
      toast.error('Failed to delete session', {
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      setIsDeletingLoader(false);
      setShowConfirmBox(false);
    }
  };

  return (
    <div className="resource-panel">
      <div className="resource-list">
        {resource === 'Practitioner' && (
          <PatientTable
            patientResponse={paginatedData}
            classes={classes}
            handleDeleteIconClick={handleDeleteIconClick}
          />
        )}
        <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
          <Pagination
            className="pagination"
            value={currentPage}
            total={Math.ceil(patientResponse.length / perPage)}
            onChange={setCurrentPage}
          />
        </Center>
      </div>
      <ConfirmBox
        isOpen={showConfirmBox}
        onClose={() => setShowConfirmBox(false)}
        onConfirm={() => {
          handleConfirmDelete();
        }}
        confirm="Confirm"
        cancel="Cancel"
        message="Are you sure you want to delete this patient?"
        isLoader={isDeletingLoader}
        additionalText=""
        patient={undefined}
      />
    </div>
  );
}